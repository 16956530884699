document.addEventListener('turbo:render', function () {
  if (
    $('.nested-fields').length &&
    $('.alert-warning').length &&
    ($('body.create').length || $('body.update').length)
  ) {
    $("[id$='_destroy']").each(function () {
      $(this).val('false');
    });
  }
});

$(document).on('cocoon:before-remove', function (e) {
  var confirmation = confirm(
    'Are you sure?\n\nThis cannot be un-done once you save this form template!'
  );
  if (confirmation === false) {
    e.preventDefault();
  }
});
