export function initSelect2() {
  $('.form-floating select').each(function () {
    if ($(this).parents('.modal').length) {
      $(this).select2({
        theme: 'bootstrap-5',
        dropdownParent: $(this).parents('.modal'),
      });
    } else {
      $(this).select2({
        theme: 'bootstrap-5',
      });
    }
    // remove the calculated width to fill parent container
    $('.select2-container').removeAttr('style');
  });
}
