import { localAutoSave } from '../modules/autosave';

document.addEventListener('turbo:load', function () {
  if ($('body.client_forms.edit').length) {
    const formContent = $('form').data('form-content');

    // fill in the form with the forms content
    $.each(formContent, function (id, value) {
      if (!value) {
        return;
      }

      let $el = $(`#${id}`); // checkbox or input
      if ($el.length < 1) {
        $el = $(`#${id}_option_${value}`); // radio
      }

      if ($el.attr('type') == 'checkbox' || $el.attr('type') == 'radio') {
        $el.prop('checked', true);
      } else {
        $el.val(value);
        $el
          .parents('[data-behavior~=note-input]')
          .removeClass('d-none')
          .prev('[data-behavior~=note-reveal]')
          .addClass('d-none');
      }
    });
  }

  if ($('body.client_forms').length) {
    $('[data-behavior~=note-reveal]').on('click', function () {
      $(this)
        .addClass('d-none')
        .next('[data-behavior~=note-input]')
        .removeClass('d-none')
        .find('textarea')
        .focus();
    });

    $('[data-behavior~=note-input] textarea').on('blur', function () {
      if (!$(this).val()) {
        $(this)
          .parents('[data-behavior~=note-input]')
          .addClass('d-none')
          .prev('[data-behavior~=note-reveal]')
          .removeClass('d-none');
      }
    });

    // Init autosave
    $('form[data-local-storage-key]').each(function () {
      new localAutoSave(this);
    });
  }

  if ($('body.client_forms.show').length) {
    function getParameterValue(parameterName) {
      var urlSearchParams = new URLSearchParams(window.location.search);
      return urlSearchParams.get(parameterName);
    }

    var clearCacheValidationValue = getParameterValue('clear_cache_validation');
    if (clearCacheValidationValue !== null) {
      localStorage.removeItem(clearCacheValidationValue);
    }
  }
});
