export function initAvatars() {
  if (document.querySelector('[data-avatar-color]')) {
    // Coefficients (per color channel) for converting gamma-compressed rgb to
    // linear rgb in order to reflect the color's luminance as perceived by humans.
    const coefficientB = 0.0722,
          coefficientG = 0.7152,
          coefficientR = 0.2126,
          regex = /^#?([A-Fa-f0-9]{6})$/;

    String.prototype.toRGB = function() {
      // Ensure string is valid Hex (shorthand or full)
      if (!regex.test(this)) { return false; }

      var hexString = this;

      // Strip out the # if it's present
      if (hexString.charAt(0) == '#') {
        hexString = hexString.substring(1);
      }

      // Convert full hex to array of rgb values
      var hexArray = hexString.match(/.{1,2}/g),
          rgbArray = [
            parseInt(hexArray[0], 16),
            parseInt(hexArray[1], 16),
            parseInt(hexArray[2], 16)
          ];

      return {
        rgb: rgbArray,
        hex: `#${hexString}`
      };
    };

    document.querySelectorAll('[data-avatar-color]').forEach(function(avatar) {
      const hex = avatar.getAttribute('data-avatar-color').toString(),
            color = hex.toRGB();

      if (color.rgb) {
        // Set badge background color
        avatar.style.backgroundColor = color.hex;

        // Set badge text to white if background color is dark
        if (color.rgb[0] * coefficientR + color.rgb[1] * coefficientG + color.rgb[2] * coefficientB < 255 / 2) {
          avatar.classList.add('text-white');
        } else {
          avatar.classList.add('text-black');
        }
      }
    });
  }
}
