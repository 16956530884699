document.addEventListener('turbo:load', function(){
  if ($('body.users.edit').length) {
    function previewImage(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          let css = $('[data-behavior~=avatar-container] :first-child').attr('class');
          let new_html = `<img class="${css}" src="${e.target.result}">`;
          
          $('[data-behavior~=avatar-container').html(new_html);
        };

        reader.readAsDataURL(input.files[0]);
      }
    }

    $('[data-behavior~=img-upload-input').on('change', function() {
      previewImage(this);
    });
  }
});
