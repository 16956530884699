document.addEventListener('turbo:load', function() {
  if ($('[data-behavior~=header-text]').length) {

    function setMaxWidth() {
      if ($(window).width() < 576) { 
        $('[data-behavior~=header-text]').each(function() {
          $(this).removeAttr('style');
        })
        return 
      }

      $('[data-behavior~=header-text]').each(function() {
        const parentContainer = $(this).parents('.container'),
        headerActionsContainer = $(this).next('.header-actions'),
        headerAvatarContainer = parentContainer.find('[data-avatar-color]');
  
        $(this).css('max-width', (parentContainer.width() - headerActionsContainer.width() - headerAvatarContainer.width() - (parseInt(headerAvatarContainer.css('margin-right')) * 4)))
      })
    }
    
    setMaxWidth()

    $(window).on('resize', setMaxWidth)
  }
});
