export function initDataTables() {
  if ($('table').length) {
    if ($.fn.dataTable.isDataTable('table')) {
      $('table').DataTable().destroy();
    }

    $('table').DataTable({
      dom: '<"row mb-3"<"col-sm-6"l><"col-sm-6"f>>rt<"row mt-3"<"col-md-6 mb-3 mb-md-0"i><"col-md-6"p>>',
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, 'All'],
      ],
      order: [],
      pageLength: 50,
      stateSave: true,
    });
  }
}
