import { initAvatars } from '../modules/avatars';
import { initDataTables } from '../modules/datatables';

document.addEventListener('turbo:load', function () {
  if ($('body.insights').length) {
    $('[data-behavior~=insights-filters] select').on('change', function () {
      submitFormWithTurbo($(this).closest('form'));
    });

    $('[data-behavior~=insights-filters] input[type="date"]').on(
      'change',
      function () {
        handleDateInputChange($(this));
      }
    );

    // Initial page load
    submitFormWithTurbo($('form'));

    function handleDateInputChange(input) {
      const dateValue = input.val();
      const $periodStart = $('#period_start');
      const $periodEnd = $('#period_end');
      const minPeriodStart = $periodStart.attr('min');

      if ($periodStart.val() === '') {
        submitFormWithTurbo(input.closest('form'));
        return;
      }

      if ($periodEnd.val() === '') {
        // if the end date is cleared, set it to today
        $periodEnd.val(new Date().toISOString().slice(0, 10));
        submitFormWithTurbo(input.closest('form'));
        return;
      }

      if (dateValue !== '' && !isNaN(Date.parse(dateValue))) {
        if (Date.parse(dateValue) >= Date.parse(minPeriodStart)) {
          submitFormWithTurbo(input.closest('form'));
        }
      }
    }

    // Submit the form using Turbo
    function submitFormWithTurbo(form) {
      const formData = form.serialize();
      const formUrl = form.attr('action');
      const $tabPanes = $('.tab-pane');

      $tabPanes.each(function () {
        $(this).empty().append(
          '<div class="text-center">\
            <div class="spinner-border text-primary" role="status"></div>\
            <p class="text-muted mt-3">Loading... this may take a minute or two</p>\
          </div>'
        );
      });

      Turbo.visit(
        `${formUrl}?${formData}&tab=${$('.tab-pane.active').attr('id')}`,
        {
          frame: 'insights',
        }
      );
    }
  }
});

document.addEventListener('turbo:frame-load', function () {
  if ($('body.insights')) {
    initAvatars();
    initDataTables();
  }
});
