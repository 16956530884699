import * as bootstrap from 'bootstrap';
import { initAvatars } from './modules/avatars';
import { initDataTables } from './modules/datatables';
import { initSelect2 } from './modules/select2';
import { initSendEmailDropdown } from './modules/send_email_dropdown';

document.addEventListener('turbo:load', function () {
  // Init Bootstrap toasts (used as alerts)
  var toastElList = [].slice.call(document.querySelectorAll('.toast'));
  var toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl, {
      autohide: true,
    }).show();
  });

  initAvatars();
  initDataTables();
  initSelect2();
  initSendEmailDropdown();
});

document.addEventListener('turbo:before-cache', function () {
  // Unbind DataTable to avoid multiple bindings on page load after navigation
  if ($.fn.dataTable.isDataTable('table')) {
    $('table').DataTable().destroy();
  }
});
