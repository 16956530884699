export function initSendEmailDropdown() {
  function toggleSendBtn($input) {
    var isEmpty =
      !$input
        .parents('form')
        .find($('[data-behavior~=send-to-client-checkbox]'))
        .prop('checked') &&
      !$input
        .parents('form')
        .find($('[data-behavior~=send-to-doctor-checkbox]'))
        .prop('checked') &&
      !$input
        .parents('form')
        .find($('[data-behavior~=send-to-email-input]'))
        .val();

    $input
      .parents('form')
      .find($('[data-behavior~=send-btn]'))
      .toggleClass('disabled', isEmpty);
  }

  if ($('[data-behavior~=send-email-form]').length) {
    $('[data-behavior~=send-to-checkbox]').on('change', function () {
      toggleSendBtn($(this));
    });
    $('[data-behavior~=send-to-email-input]').on('input', function () {
      toggleSendBtn($(this));
    });
  }
}
