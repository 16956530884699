document.addEventListener('turbo:load', function(){
  if ($('body.form_templates.new'.length)) {

    $(document).on('cocoon:after-insert', function(e, insertedItem) {
      if (insertedItem.data('behavior') == 'form-group' && insertedItem.find('[data-behavior~=form-field]').length < 1) {
        insertedItem.find('[data-behavior~=add-field]').click();
      }
    })
  }

  function toggleFieldOptions($select) {
    $select
    .parents('[data-behavior~=form-field]')
    .find('[data-behavior~=field-options]')
    .toggleClass('d-none', !($select.val() == 'check' || $select.val() == 'radio'));
  }

  if ($('body.form_templates.new, body.form_templates.edit, body.form_templates.create').length) {
    $('[data-behavior~=field-type-select]').each(function() {
      toggleFieldOptions($(this))
    })

    $('[data-behavior~=field-type-select]').on('change', function() {
      toggleFieldOptions($(this))
    })

    $(document).on('cocoon:after-insert', function() {
      $('[data-behavior~=field-type-select]').on('change', function() {
        toggleFieldOptions($(this))
      })
    })
  }
})
