import { initAvatars } from '../modules/avatars';
import { initSendEmailDropdown } from '../modules/send_email_dropdown';
import { localAutoSave } from '../modules/autosave';

// Callback function to re-init Avatars when mutations are observed in the notes_feed
const callback = function (mutationsList) {
  mutationsList.forEach(function (mutation) {
    if (mutation.addedNodes.length) {
      mutation.addedNodes.forEach(function (node) {
        if (node.nodeType === 1) {
          initSendEmailDropdown();

          // Init autosave on the newly added note in case it's going to be edited
          // before a page reload or navigation
          if ($(node).find('[data-local-storage-key]').length) {
            new localAutoSave($(node).find('[data-local-storage-key]')[0]);
          }

          // Clear the cache for a new note when the note has been successfully saved.
          // This attr is populated from the server via turbo_steam to ensure there
          // wasn't an error, outage, or session timeout when the note was attempted
          // to be saved via Turbo. If the key was cleared on submit and the server was
          // down, the data would be cleared from cache and the note would not have been
          // created in the db, resulting in data-loss.
          let cacheKeyToClear = $(node)
            .find('[data-clear-cache-validation]')
            .attr('data-clear-cache-validation'); // this ensure it has a value
          if (cacheKeyToClear) {
            localStorage.removeItem(cacheKeyToClear);
          }

          if ($(node).find('[data-avatar-color]').length) {
            initAvatars();
          }
        }
      });
    }
  });
};

// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);

document.addEventListener('turbo:load', function () {
  if ($('body.clients.show').length) {
    // Init autosave
    $('form[data-local-storage-key]').each(function () {
      new localAutoSave(this);
    });

    // Check for DOM mutations that require an Avatar re-init (content loaded via turbo)
    const targetNode = document.getElementById('notes_feed');
    const config = { attributes: false, childList: true, subtree: true };

    observer.observe(targetNode, config);

    // Limit attachments to jpeg/jpg/png and the size to 10MB
    window.addEventListener('trix-file-accept', function (event) {
      const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      const $errorElement = $(event.target).next(
        '[data-behavior~=attachment-error]'
      );
      const maxFileSize = 10 * 1024 * 1024; // 10MB
      let errorMsgs = [];

      if (!acceptedTypes.includes(event.file.type)) {
        event.preventDefault();
        errorMsgs.push('attachments must be in .jpg/.jpeg, or .png format');
      }

      if (event.file.size > maxFileSize) {
        event.preventDefault();
        errorMsgs.push('attachments must be less than 10MB each');
      }

      if (errorMsgs.length > 0) {
        $errorElement
          .html(`Error: ${errorMsgs.join(' and ')}`)
          .removeClass('d-none');
      } else {
        $errorElement.html('').addClass('d-none');
      }
    });
  }
});

document.addEventListener('turbo:before-cache', function () {
  if (observer.length) {
    observer.disconnect();
  }
});
