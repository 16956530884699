export class localAutoSave {
  constructor(target) {
    if (target.tagName !== 'FORM') {
      console.error('Local autosave can only be initialized on a form');
      return;
    }
    this.cancelled = false;
    this.debounceTimer = 500;
    this.key = target.dataset.localStorageKey;
    this.submitted = false;
    this.target = target;

    // Don't store authenticity_token and utf8
    this.excludedInputNames = ['utf8', 'authenticity_token', '_method'];

    this.init();
  }

  init() {
    this.behaviors();
    this.restoreData();
  }

  behaviors() {
    var that = this;

    this.target.addEventListener('submit', function (event) {
      that.submitted = true;
    });

    var clearCacheElement = this.target.querySelector(
      '[data-behavior~=clear-local-auto-save]'
    );

    if (clearCacheElement) {
      clearCacheElement.addEventListener('click', function (event) {
        that.cancelled = true;
        localStorage.removeItem(that.key);
      });
    }

    this.target
      .querySelectorAll('input, textarea, select, trix-editor')
      .forEach(function (input) {
        // Don't add event handler for submit button
        if (input.type === 'button') {
          return true;
        }

        $(input).on(
          'trix-change change input',
          that.handleTextChange.bind(that)
        );
      });
  }

  handleTextChange() {
    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(
      function () {
        this.setData();
      }.bind(this),
      this.debounceTimer
    );
  }

  getData() {
    var that = this;
    var data = new FormData(this.target);
    var dataArray = Array.from(data).filter(function (dataElement) {
      return !that.excludedInputNames.includes(dataElement[0]);
    });

    return dataArray;
  }

  restoreData() {
    var that = this;
    var data = JSON.parse(localStorage.getItem(this.key));

    if (data !== null) {
      for (var item of data) {
        var name = item[0];
        var element = that.target.elements[name];

        // Skip loop if element is undefined
        if (!element) {
          continue;
        }

        // RadioNodeList is an array of checkboxes or radio buttons
        if (element instanceof RadioNodeList) {
          element.forEach(function (radioNodeListElement) {
            if (['checkbox', 'radio'].includes(radioNodeListElement.type)) {
              if (radioNodeListElement.value === item[1]) {
                radioNodeListElement.checked = true;
              }
            }
          });
        } else if (element.type === 'checkbox' || element.type === 'radio') {
          if (element.value === item[1]) {
            element.checked = true;
          }
        } else if (element.type === 'file') {
          element.value = '';
        } else {
          element.value = item[1];

          // update trix editor with cached content
          if ($(`trix-editor[input='${element.id}']`).length) {
            $(`trix-editor[input='${element.id}']`)[0].editor.loadHTML(
              element.value
            );
          }

          // reveal notes input box for cached content in client form
          if (
            $(element).parents('[data-behavior~=note-input]').length &&
            $(element).val()
          ) {
            $(element)
              .parents('[data-behavior~=note-input]')
              .removeClass('d-none')
              .prev('[data-behavior~=note-reveal]')
              .addClass('d-none');
          }
        }
      }
    }
  }

  setData() {
    if (!this.cancelled && !this.submitted) {
      localStorage.setItem(this.key, JSON.stringify(this.getData()));
      $(this.target)
        .find('[data-behavior~=clear-cache-validation]')
        .attr('value', this.key);
    }
  }
}
