document.addEventListener('turbo:load', function () {
  if ($('body.export_client_emails, body.export_doctor_emails').length) {
    $('[data-behavior~=export-data]').height(
      $('[data-behavior~=export-data]')[0].scrollHeight
    );

    $('[data-behavior~=copy]').on('click', function () {
      const btnText = $('[data-behavior~=copy] span').text(),
        btnWidth = $('[data-behavior~=copy]').width();
      navigator.clipboard
        .writeText($('[data-behavior~=export-data]').val())
        .then(
          function () {
            $('[data-behavior~=copy]').width(btnWidth);
            $('[data-behavior~=copy] i')
              .removeClass('fa-copy')
              .addClass('fa-check');
            $('[data-behavior~=copy] span').text('Copied!');
          },
          function () {
            $('[data-behavior~=copy]').width(btnWidth);
            $('[data-behavior~=copy] i')
              .removeClass('fa-copy')
              .addClass('fa-times');
            $('[data-behavior~=copy] span').text('Error!');
          }
        )
        .then(
          setTimeout(function () {
            $('[data-behavior~=copy] i')
              .removeClass('fa-check fa-times')
              .addClass('fa-copy');
            $('[data-behavior~=copy] span').text(btnText);
          }, 1500)
        );
    });
  }
});
