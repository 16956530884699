document.addEventListener('turbo:load', function(){
  if ($('body.login_requests.index').length) {

    if ($.fn.dataTable.isDataTable( 'table' )) {
      $('table').DataTable().destroy()
    }
    
    $('table').DataTable({
      dom: '<"row mb-3"<"col-6"l><"col-6"f>>rt<"row mt-3"<"col-6"i><"col-6"p>>',
      columnDefs: [
        {
          targets: [-1],
          className: 'dt-body-right',
          orderable: false,
          searchable: false
        }
      ],
      lengthMenu: [ [15, 25, 50, -1], [15, 25, 50, "All"] ],
      pageLength: 15,
      stateSave: true
    });
  }
})
